<template>
  <div>
    <div class="table-header-bar">
      <el-button type="primary" @click="showSyncDialogAction"
        >Data Synchronization</el-button
      >
      <el-input
        v-model="searchQuery"
        placeholder="Enter sync name or SKU"
        clearable
        @keyup.enter="searchDataSync"
        style="width: 300px"
      >
        <template #append>
          <el-button @click="searchDataSync">Search</el-button>
        </template>
      </el-input>
    </div>
    <el-table
      :data="syncItems"
      style="width: 100%; border-radius: 8px; overflow: hidden"
      v-loading="pageloading"
      stripe
      highlight-current-row
      border
      @sort-change="handleSortChange"
    >
      <el-table-column
        prop="name"
        label="Sync Name"
        align="center"
        header-align="center"
        sortable="custom"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="skuShopify"
        label="SKU (Shopify)"
        align="center"
        header-align="center"
        width="160"
      >
        <template #default="scope">
          <div style="white-space: pre-line">
            {{ formatSku(scope.row, null, scope.row.skuShopify) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="skuEbayOne"
        label="SKU (Ebay-A)"
        align="center"
        header-align="center"
        width="160"
      >
        <template #default="scope">
          <div style="white-space: pre-line">
            {{ formatSku(scope.row, null, scope.row.skuEbayOne) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="skuEbayTwo"
        label="SKU (Ebay-B)"
        align="center"
        header-align="center"
        width="160"
      >
        <template #default="scope">
          <div style="white-space: pre-line">
            {{ formatSku(scope.row, null, scope.row.skuEbayTwo) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="skuWordpress"
        label="SKU (Wordpress)"
        align="center"
        header-align="center"
        width="140"
      >
        <template #default="scope">
          <div style="white-space: pre-line">
            {{ formatSku(scope.row, null, scope.row.skuWordpress) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="virtualStock"
        label="Virtual Stock"
        sortable="custom"
        align="center"
        header-align="center"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="realStock"
        label="Actual Stock"
        sortable="custom"
        align="center"
        header-align="center"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="updatedAt"
        label="Last Updated"
        width="140"
        align="center"
      >
        <template #default="{ row }">
          <span style="color: #666">
            {{ formatDate(row.updatedAt) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column 
      label="Actions" 
      align="center"
      fixed="right"
      >
        <template #default="{ row }">
          <div class="action-buttons">
            <el-button
              size="mini"
              type="primary"
              style="margin-left: 10px"
              @click="editItem(row)"
              >Edit</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="openSyncStockDialog(row)"
              >Sync Stock</el-button
            >
            <el-button size="mini" type="danger" @click="deleteItem(row)"
              >Delete</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[20, 40, 60, 80]"
      :page-size="20"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    >
    </el-pagination>

    <!-- 同步库存对话框 -->
    <el-dialog title="Sync Stock" v-model="showSyncStockDialog" width="400px">
      <el-form :model="syncStockData">
        <el-form-item label="virtualStock" label-width="120px">
          <el-input
            v-model="syncStockData.virtualStock"
            type="number"
            placeholder="Please enter the quantity"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="realStock" label-width="120px">
          <el-input
            v-model="syncStockData.realStock"
            type="number"
            placeholder="Please enter the quantity"
            style="width: 200px"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showSyncStockDialog = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="syncStock"
            :loading="syncLoading"
            :disabled="syncLoading"
            >Confirm</el-button
          >
        </div>
      </template>
    </el-dialog>

    <!-- 同步数据对话框 -->
    <el-dialog title="" v-model="showSyncDialog" width="400px">
      <el-form :model="formData">
        <el-form-item label="Sync Name" label-width="120">
          <el-input v-model="formData.name" style="width: 200px"></el-input>
        </el-form-item>

        <!-- 修改：Shopify SKU 多选搜索 -->
        <el-form-item label="SKU (Shopify)" label-width="120">
          <el-select
            v-model="formData.skuShopify"
            filterable
            multiple
            remote
            reserve-keyword
            placeholder="Please search and select SKUs"
            :remote-method="fetchSkuShopify"
            :loading="loading"
            style="width: 300px"
          >
            <el-option
              v-for="item in shopifyOptions"
              :key="item.id"
              :label="item.sku"
              :value="item.sku"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="SKU (Ebay-A)" label-width="120">
          <el-select
            v-model="formData.skuEbayOne"
            multiple
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a itemId"
            :remote-method="
              (inputValue) => fetchSkuDataEbay(inputValue, 'dahao')
            "
            :loading="loading"
            style="width: 300px"
          >
            <el-option
              v-for="item in ebayOneOptions"
              :key="item.id"
              :label="item.sku"
              :value="item.sku"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="SKU (Ebay-B)" label-width="120">
          <el-select
            v-model="formData.skuEbayTwo"
            multiple
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a itemId"
            :remote-method="
              (inputValue) => fetchSkuDataEbay(inputValue, 'xiaohao')
            "
            :loading="loading"
            style="width: 300px"
          >
            <el-option
              v-for="item in ebayTwoOptions"
              :key="item.id"
              :label="item.sku"
              :value="item.sku"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="SKU (WordPress)" label-width="120">
          <el-select
            v-model="formData.skuWordpress"
            multiple
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a sku"
            :remote-method="fetchSkuDataWordpress"
            :loading="loading"
            style="width: 300px"
          >
            <el-option
              v-for="item in wordpressOptions"
              :key="item.id"
              :label="item.sku"
              :value="item.sku"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <div class="dialog-footer">
          <el-button @click="showSyncDialog = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="syncData"
            :loading="saveOrUpdateloading"
            >Save</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import apiClient from "@/apiClient";

export default {
  name: "DataSync",
  data() {
    return {
      sortField: "",
      sortOrder: "",
      shopifyCache: [], // Shopify 缓存
      ebayOneCache: [], // eBay-A 缓存
      ebayTwoCache: [], // eBay-B 缓存
      wordpressCache: [], // WordPress 缓存
      wordpressList: [],
      ebayTwoList: [],
      shopifyList: [],
      ebayOneList: [],
      cacheOptions: [],
      syncLoading: false,
      saveOrUpdateloading: false,
      searchQuery: "",
      syncItems: [],
      loading: false,
      pageloading: false,
      currentPage: 1,
      totalItems: 0,
      showSyncDialog: false,
      showSyncStockDialog: false, // 控制同步库存对话框的显示
      shopifyOptions: [],
      ebayOneOptions: [],
      ebayTwoOptions: [],
      wordpressOptions: [],
      shopifyOptionsAll: [],
      ebayOneOptionsAll: [],
      ebayTwoOptionsALL: [],
      wordpressOptionsAll: [],
      formData: {
        name: "",
        skuShopify: [],
        skuEbayOne: [],
        skuEbayTwo: [],
        skuWordpress: [],
        idShopify: [],
        idEbayOne: [],
        idEbayTwo: [],
        idWordpress: [],
        virtualStock: "",
        realStock: "",
      },
      syncStockData: {
        id: null, // 当前行的ID
        name: "", // 同步的名称
        skuShopify: [],
        skuEbayOne: [],
        skuEbayTwo: [],
        skuWordpress: [],
        idShopify: [],
        idEbayOne: [],
        idEbayTwo: [],
        idWordpress: [],
        virtualStock: "",
        realStock: "",
      },
    };
  },
  methods: {
    formatDate(dateStr) {
      if (!dateStr) return "";
      const date = new Date(dateStr);
      const pad = (n) => (n < 10 ? "0" + n : n);
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
      )} ${pad(date.getHours())}:${pad(date.getMinutes())}`;
    },

    handleSortChange({ prop, order }) {
      this.sortField = prop;
      this.sortOrder = order === "ascending" ? "asc" : "desc";
      this.fetchSyncData(); // 重新拉取排序后的数据
    },

    formatSku(row, column, cellValue) {
      // 如果是数组，直接 join
      if (Array.isArray(cellValue)) {
        return cellValue.join("\n");
      }

      // 如果是字符串，按逗号分隔
      if (typeof cellValue === "string" && cellValue) {
        return cellValue
          .split(",")
          .map((sku) => sku.trim())
          .join("\n");
      }

      // 其他类型直接返回空字符串
      return "";
    },
    showSyncDialogAction() {
      this.formData = {
        id: null,
        name: "",
        skuShopify: [],
        skuEbayOne: [],
        skuEbayTwo: [],
        skuWordpress: [],
        virtualStock: 0,
        realStock: 0,
      };

      this.shopifyOptions = [];
      this.ebayOneOptions = [];
      this.ebayTwoOptions = [];
      this.wordpressOptions = [];
      this.showSyncDialog = true;
    },
    searchDataSync() {
      // 重新加载数据，并进行搜索
      this.currentPage = 1;
      this.fetchSyncData();
    },
    syncData() {
      // 检查 formData 是否包含 id 属性来决定是创建还是更新
      console.log("ebay多选择");

      if (this.formData.id) {
        // 更新现有记录
        this.updateItem(this.formData);
      } else {
        // 创建新记录
        this.createItem(this.formData);
      }
    },

    createItem(data) {
      this.saveOrUpdateloading = true;

      // 创建一个通用函数用于从缓存中获取 id 列表
      const getSkuIdList = (skuList, cache) => {
        return skuList.map((sku) => {
          const cachedOption = cache.find((option) => option.sku === sku);
          return cachedOption ? cachedOption.id : null; // 如果找不到对应 sku，则返回 null
        });
      };

      // 获取每个店铺的 idList
      data.idShopify = getSkuIdList(
        this.formData.skuShopify,
        this.shopifyCache
      );
      data.idEbayOne = getSkuIdList(
        this.formData.skuEbayOne,
        this.ebayOneCache
      );
      data.idEbayTwo = getSkuIdList(
        this.formData.skuEbayTwo,
        this.ebayTwoCache
      );
      data.idWordpress = getSkuIdList(
        this.formData.skuWordpress,
        this.wordpressCache
      );

      // 提交数据到后端
      apiClient
        .post("/dataSync/save", data)
        .then(() => {
          this.$message({
            type: "success",
            message: "Synchronization task created successfully!",
          });
          this.fetchSyncData(this.currentPage);
          this.saveOrUpdateloading = false;
          this.showSyncDialog = false;
        })
        .catch((error) => {
          console.error("Failed to create synchronization task", error);
          if (error.response && error.response.data) {
            this.$message.error(`Creation failed: ${error.response.data}`);
          } else {
            this.$message.error(
              "Failed to create synchronization task, please try again later"
            );
          }
          this.showSyncDialog = true;
          this.saveOrUpdateloading = false;
        });
    },
    updateItem(data) {
      this.saveOrUpdateloading = true;

      // 创建一个通用函数用于从缓存中获取 id 列表
      const getSkuIdList = (skuList, cache) => {
        return skuList.map((sku) => {
          const cachedOption = cache.find((option) => option.sku === sku);
          return cachedOption ? cachedOption.id : null; // 如果找不到对应 sku，则返回 null
        });
      };

      // 获取每个店铺的 idList
      data.idShopify = getSkuIdList(
        this.formData.skuShopify,
        this.shopifyCache
      );
      data.idEbayOne = getSkuIdList(
        this.formData.skuEbayOne,
        this.ebayOneCache
      );
      data.idEbayTwo = getSkuIdList(
        this.formData.skuEbayTwo,
        this.ebayTwoCache
      );
      data.idWordpress = getSkuIdList(
        this.formData.skuWordpress,
        this.wordpressCache
      );

      // 提交数据到后端
      apiClient
        .put(`/dataSync/update`, data)
        .then(() => {
          this.$message({
            type: "success",
            message: "Synchronization task updated successfully!",
          });
          this.fetchSyncData(this.currentPage);
          this.showSyncDialog = false;
          this.saveOrUpdateloading = false;
        })
        .catch((error) => {
          console.error("Failed to update synchronization task", error);
          if (error.response && error.response.data) {
            this.$message.error(`Update failed: ${error.response.data}`);
          } else {
            this.$message.error(
              "Failed to update synchronization task, please try again later"
            );
          }
          this.showSyncDialog = true;
          this.saveOrUpdateloading = false;
        });
    },
    fetchSyncData(page = 1) {
      const params = {
        page: page,
        size: 20,
        search: this.searchQuery,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      };
      this.pageloading = true;
      apiClient
        .get("/dataSync/list", { params })
        .then((response) => {
          this.syncItems = response.data.items;
          this.totalItems = response.data.total;
          this.pageloading = false;
        })
        .catch((error) => {
          console.error("Failed to load data", error);
          this.pageloading = false;
        });
    },

    generateOptions(skuList, idList, cache) {
      const options = [];
      if (!cache) {
        cache = [];
      }
      if (
        Array.isArray(skuList) &&
        Array.isArray(idList) &&
        skuList.length === idList.length
      ) {
        for (let i = 0; i < skuList.length; i++) {
          const option = { id: idList[i], sku: skuList[i] };

          // 添加到对应店铺的缓存中
          if (!cache.some((item) => item.id === option.id)) {
            cache.push(option);
          }

          // 只返回 sku 字符串数组
          options.push(option.sku);
        }
      }
      return options;
    },
    editItem(item) {
      // 初始化 formData
      this.formData = {
        id: item.id,
        name: item.name,
        skuShopify: this.generateOptions(
          item.skuShopify,
          item.idShopify,
          this.shopifyCache // 使用 Shopify 缓存
        ),
        skuEbayOne: this.generateOptions(
          item.skuEbayOne,
          item.idEbayOne,
          this.ebayOneCache // 使用 eBay-A 缓存
        ),
        skuEbayTwo: this.generateOptions(
          item.skuEbayTwo,
          item.idEbayTwo,
          this.ebayTwoCache // 使用 eBay-B 缓存
        ),
        skuWordpress: this.generateOptions(
          item.skuWordpress,
          item.idWordpress,
          this.wordpressCache // 使用 WordPress 缓存
        ),
        virtualStock: item.virtualStock,
        realStock: item.realStock,
      };

      // 动态生成选项列表
      this.shopifyOptions = this.shopifyCache.filter((option) =>
        this.formData.skuShopify.includes(option.sku)
      );
      this.ebayOneOptions = this.ebayOneCache.filter((option) =>
        this.formData.skuEbayOne.includes(option.sku)
      );
      this.ebayTwoOptions = this.ebayTwoCache.filter((option) =>
        this.formData.skuEbayTwo.includes(option.sku)
      );
      this.wordpressOptions = this.wordpressCache.filter((option) =>
        this.formData.skuWordpress.includes(option.sku)
      );

      // 打开编辑窗口
      this.showSyncDialog = true;
    },
    deleteItem(item) {
      this.$confirm(
        `Are you sure you want to delete the synchronization task "${item.name}" ?`,
        "Warning",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          apiClient
            .delete(`/dataSync/delete/${item.id}`)
            .then(() => {
              this.$message({
                type: "success",
                message: "Deleted successfully!",
              });
              this.fetchSyncData(this.currentPage);
            })
            .catch((error) => {
              console.error("Failed to delete", error);
              this.$message({
                type: "error",
                message: `Deletion failed: ${
                  error.response.data || "Unknown error"
                }`,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Deletion canceled",
          });
        });
    },
    handleSizeChange(size) {
      this.fetchSyncData(this.currentPage, size);
    },
    handleCurrentChange(page) {
      this.fetchSyncData(page);
    },

    openSyncStockDialog(row) {
      this.syncStockData = { ...row };
      this.showSyncStockDialog = true;
    },

    async syncStock() {
      if (this.syncLoading) return; // Prevent double-click
      this.syncLoading = true;
      try {
        await apiClient.post("/dataSync/syncStock", this.syncStockData);
        this.$message.success("Stock synchronized successfully!");
        this.showSyncStockDialog = false;
      } catch (error) {
        this.$message.error(
          "Failed to synchronize stock: " +
            (error.response?.data || "Unknown error")
        );
      } finally {
        this.syncLoading = false; // Reset loading state after completion
        this.fetchSyncData(this.currentPage);
      }
    },

    fetchSkuShopify(query) {
      if (query.length > 2) {
        this.loading = true;
        apiClient
          .get(`/dataSync/sku?platform=shopify&sku=${query}`)
          .then((response) => {
            const node = response.data;

            // 如果没有找到数据，则清空选项列表
            if (!node.id || node.id === "未找到 ItemID") {
              this.shopifyOptions = [];
              this.loading = false;
              return;
            }

            // 定义一个通用函数用于去重更新下拉选项
            const updateOptions = (options, newItem) => {
              if (!options.some((option) => option.id === newItem.id)) {
                options.push(newItem); // 如果不存在，则添加
              }
            };

            // 更新下拉选项
            updateOptions(this.shopifyOptions, {
              id: node.id,
              sku: node.sku,
            });

            // 将新数据添加到缓存中（确保唯一性）
            if (!this.shopifyCache.some((option) => option.id === node.id)) {
              this.shopifyCache.push({ id: node.id, sku: node.sku });
            }

            this.loading = false;
          })
          .catch((error) => console.error("Failed to fetch SKU:", error));
      }
    },
    fetchSkuDataEbay(query, storeName) {
      if (query.length > 2) {
        this.loading = true;
        apiClient
          .get(
            `/dataSync/sku?platform=ebay&sku=${query}&storeName=${storeName}`
          )
          .then((response) => {
            const node = response.data;
            console.log("response:...");

            if (node.id === "未找到 ItemID") {
              this.loading = false;
              return;
            }
            // 定义一个通用函数用于去重更新下拉选项
            const updateOptions = (options, newItem) => {
              if (!options.some((option) => option.id === newItem.id)) {
                options.push(newItem); // 如果不存在，则添加
              }
            };

            // 更新下拉选项
            if (storeName === "dahao") {
              updateOptions(this.ebayOneOptions, {
                id: node.id,
                sku: node.sku,
              });
              // 将新数据添加到缓存中（确保唯一性）
              if (!this.ebayOneCache.some((option) => option.id === node.id)) {
                this.ebayOneCache.push({ id: node.id, sku: node.sku });
              }
            } else if (storeName === "xiaohao") {
              updateOptions(this.ebayTwoOptions, {
                id: node.id,
                sku: node.sku,
              });
              // 将新数据添加到缓存中（确保唯一性）
              if (!this.ebayTwoCache.some((option) => option.id === node.id)) {
                this.ebayTwoCache.push({ id: node.id, sku: node.sku });
              }
            }

            this.loading = false;
          })
          .catch((error) => console.error("Failed to fetch SKU:", error));
      }
    },
    fetchSkuDataWordpress(query) {
      if (query.length > 2) {
        this.loading = true;
        apiClient
          .get(`/dataSync/sku?platform=wordpress&sku=${query}`)
          .then((response) => {
            const node = response.data;

            // 如果没有找到数据，则清空选项列表
            if (!node.id || node.id === "未找到 ItemID") {
              this.wordpressOptions = [];
              this.loading = false;
              return;
            }

            // 定义一个通用函数用于去重更新下拉选项
            const updateOptions = (options, newItem) => {
              if (!options.some((option) => option.id === newItem.id)) {
                options.push(newItem); // 如果不存在，则添加
              }
            };

            // 更新下拉选项
            updateOptions(this.wordpressOptions, {
              id: node.id,
              sku: node.sku,
            });

            // 将新数据添加到缓存中（确保唯一性）
            if (!this.wordpressCache.some((option) => option.id === node.id)) {
              this.wordpressCache.push({ id: node.id, sku: node.sku });
            }

            this.loading = false;
          })
          .catch((error) => console.error("Failed to fetch SKU:", error));
      }
    },
  },
  created() {
    this.fetchSyncData(this.currentPage);
  },
};
</script>

<style scoped>
.table-header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
  flex-wrap: wrap;
}

.action-buttons {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  gap: 8px; /* 按钮之间的间距 */
  justify-content: center; /* 水平居中对齐 */
}

.el-button {
  min-width: 69px; /* 设置按钮的最小宽度，确保大小一致 */
  padding: 0px 0; /* 调整内边距 */
  text-align: center; /* 居中对齐 */
  margin-right: 10px;
}

.sync-button {
  margin-bottom: 20px;
}

.action-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .action-buttons {
    justify-content: space-between;
  }
}
</style>
