<template>
  <el-menu
    :default-active="activeIndex"
    class="sidebar"
    background-color="#182848"
    text-color="#fff"
    active-text-color="#ffd04b"
    @select="handleMenuSelect"
  >
    <el-menu-item index="1">
      <router-link to="/data-sync" class="menu-link">Data Sync</router-link>
    </el-menu-item>
    <el-menu-item index="2">
      <router-link to="/other" class="menu-link">Other</router-link>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    handleMenuSelect(index) {
      this.activeIndex = index;
      this.$emit("menu-select", index);
    },
  },
  created() {
    this.handleMenuSelect("1");
  }
};
</script>

<style scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(to bottom, #4b6cb7, #182848);
  border-right: none;
  transition: all 0.3s ease;
}

.menu-link {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.menu-link:hover {
  color: #ffd04b;
  text-decoration: underline;
}

.el-menu-item.is-active .menu-link {
  color: #ffd04b;
  font-weight: bold;
  text-decoration: underline;
}

.el-menu-item {
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.el-menu-item:hover {
  background-color: #2c3e50;
}
</style>
