<template>
  <el-container class="homepage-container">
    <el-aside class="sidebar-container" v-show="!isMobile || sidebarVisible">
      <sideBar />
    </el-aside>
    <el-container>
      <el-header class="header-container">
        <el-button
          class="menu-toggle-btn"
          icon="el-icon-menu"
          @click="toggleSidebar"
          v-if="isMobile"
        />
        <h1 class="title">{{ currentTitle }}</h1>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SideBar from "./SideBar.vue";

export default {
  name: "HomePage",
  components: {
    SideBar,
  },
  data() {
    return {
      isMobile: false,
      sidebarVisible: true,
      currentTitle: "Dashboard", // 默认标题
    };
  },
  created() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    // 如果当前路由为空或未指定，默认跳转到 /data-sync
    if (this.$route.path === "/home" || this.$route.path === "/") {
      this.$router.replace("/data-sync");
    } else {
      this.updateTitle(); // 更新标题
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  watch: {
    $route(to) {
      console.log(to)
      this.updateTitle(); // 路由变化时更新标题
    },
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
      if (this.isMobile) {
        this.sidebarVisible = false;
      }
    },
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    updateTitle() {
      // 根据路由路径设置标题
      const routePath = this.$route.path;
      if (routePath === "/data-sync") {
        this.currentTitle = "Data Sync";
      } else if (routePath === "/other") {
        this.currentTitle = "Other";
      } else {
        this.currentTitle = "Dashboard"; // 默认标题
      }
    },
  },
};
</script>

<style scoped>
.homepage-container {
  height: 100vh;
}

.sidebar-container {
  width: 100px;
  background: linear-gradient(to bottom, #4b6cb7, #182848);
  transition: all 0.3s ease;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #545c64;
  color: white;
}

.title {
  margin: 0;
}

.menu-toggle-btn {
  margin-right: 10px;
}
</style>
